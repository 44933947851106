<template>
  <div class="table-container">
    <table>
      <thead role="rowgroup">
      <tr role="row">
        <th v-for="(field, n) in fields" :key="n" role="columnheader" scope="col">
          {{ normalizeField(field) }}
        </th>
      </tr>
      </thead>
      <tbody role="rowgroup">
      <tr v-if="busy" role="row">
        <td :colspan="fields.length">
          <div class="text-center text-primary my-2">
            <span aria-hidden="true" class="spinner-border align-middle"></span>
          </div>
        </td>
      </tr>
      <tr v-else-if="items.length === 0" role="row" class="no-register">
        <td :colspan="fields.length" class="no-records-cell">
          <div class="container-nd">
            Não há registros que correspondam à sua solicitação
          </div>
        </td>
      </tr>
      <tr v-else v-for="(item, n) in items" :key="n" role="row">
        <td v-for="(field, i) in fields" :key="i" @click="$emit('row-click', item)"
            :class="clickable ? 'clickable' : ''" :data-label="normalizeField(field)">
          <div v-if="$scopedSlots[`cell(${getFieldKey(field)})`]">
            <slot :name="`cell(${getFieldKey(field)})`" :data="getValue(item, field)"
                  :item="item"></slot>
          </div>
          <div v-else>{{ getValue(item, field) }}</div>
        </td>
        <td v-if="$scopedSlots['actions']">
          <slot name="actions" :data="item"></slot>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableComponent',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      q: '',
    };
  },
  mounted() {
  },
  methods: {
    rowClick(field, item) {
      if (field.key !== 'actions') {
        this.$root.$emit('row-click', item);
      }
    },
    getFieldKey(field) {
      return field.key ?? field;
    },
    normalizeField(field) {
      let normalizedField = field;
      if (typeof field === 'string') {
        normalizedField = this.$tc(`app.${field}`, 1);
      } else if (field.label) {
        normalizedField = field.label;
      } else {
        normalizedField = this.$tc(`app.${field.key}`, 1);
      }
      return normalizedField;
    },
    getValue(item, field) {
      const key = this.getFieldKey(field);
      const keys = key.split('.'); // Suporte para chaves aninhadas
      const value = keys.reduce((acc, k) => {
        if (acc && acc[k] !== undefined) {
          return acc[k];
        }
        return undefined;
      }, item);

      if (field.formatter) {
        return field.formatter(value);
      }

      return value;
    },
  },
};
</script>

<style scoped lang="scss">
.table-container {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: auto;
    color: #6F6F6F;
    margin-bottom: 1rem;
    table-layout: fixed;

    thead {
      th {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        padding: 12px;
        white-space: nowrap;
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
        }

        td {
          font-size: 14px;
          font-weight: 400;
          padding: 12px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .clickable {
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.table-container table tbody tr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
}

.table-container table tbody {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.table-container table thead tr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 3px solid rgba(107, 35, 220, 0.2);
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;
}

.table-container table tbody tr td {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container table thead tr th {
  width: 25%;
  text-align: center
}

.no-records-cell {
  width: 100% !important;
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.container-nd {
  width: 100% !important;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  padding: 10px;
  box-sizing: border-box;
}

.spinner {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.spinner-div {
  width: 100%;
  height: 100%;
}

.spinner-border {
  margin: auto;
  color: rgba(107, 35, 220, 0.8) !important;
}

@media (max-width: 1200px) {

  .table-container table tbody tr {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
  }

  .table-container table tbody tr td {
    text-align: end;
  }

  table {
    thead {
      display: none;
    }

    td {
      text-align: right;
      position: relative;
      padding-left: 50%;
      height: auto;
      border: none;
      width: 100% !important;
      box-sizing: border-box;

      &:before {
        content: attr(data-label);
        position: absolute;
        left: 16px;
        width: calc(50% - 16px);
        text-align: left;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .container-nd {
    font-size: 14px;
    padding: 5px;
  }

  .no-register {
    flex-direction: column;
    padding: 5px;
  }

  .no-records-cell {
    text-align: center !important;
    padding: 5px;
  }

  .table-container table tbody tr td {
    width: 100% !important;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

.spinner {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.spinner-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}
</style>
